import React from 'react';
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper
} from '@mui/material';
import { Calculate, Psychology, RemoveRedEye, Favorite } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const CalculatorsPage: React.FC = () => {
  const navigate = useNavigate();

  const calculators = [
    {
      title: 'حاسبة فك السحر والعكوسات',
      description: 'تحليل شامل لحالتك وتحديد نوع العلاج المناسب لفك السحر',
      icon: <Psychology sx={{ fontSize: 40 }} />,
      link: '/حاسبات/فك-السحر'
    },
    {
      title: 'حاسبة علاج الحسد والعين',
      description: 'تحليل مستوى الإصابة بالعين والحسد وطرق العلاج',
      icon: <RemoveRedEye sx={{ fontSize: 40 }} />,
      link: '/حاسبات/علاج-الحسد'
    },
    {
      title: 'حاسبة التوافق الروحي',
      description: 'تحليل التوافق الروحي بين شخصين وتقديم النصائح المناسبة',
      icon: <Favorite sx={{ fontSize: 40 }} />,
      link: '/حاسبات/التوافق-الروحي'
    }
  ];

  return (
    <Box sx={{ py: { xs: 4, md: 6 }, backgroundColor: '#f8f8f8' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '1.8rem', md: '2.2rem' },
            fontWeight: 700,
            textAlign: 'center',
            color: '#006633',
            mb: 2,
            fontFamily: 'Tajawal'
          }}
        >
          الحاسبات الروحانية
        </Typography>

        <Typography
          sx={{
            textAlign: 'center',
            color: '#666',
            mb: 6,
            fontFamily: 'Tajawal',
            fontSize: { xs: '1rem', md: '1.1rem' },
            maxWidth: '700px',
            mx: 'auto'
          }}
        >
          اختر الحاسبة المناسبة لحالتك للحصول على تحليل دقيق وتوصيات مخصصة
        </Typography>

        <Grid container spacing={3}>
          {calculators.map((calc, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper
                onClick={() => navigate(calc.link)}
                sx={{
                  p: 4,
                  height: '100%',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  backgroundColor: 'white',
                  borderRadius: 2,
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 8px 24px rgba(0,102,51,0.15)'
                  }
                }}
              >
                <Box
                  sx={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0,102,51,0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 3,
                    color: '#006633',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#006633',
                      color: 'white',
                      transform: 'rotate(360deg)'
                    }
                  }}
                >
                  {calc.icon}
                </Box>

                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Tajawal',
                    fontWeight: 700,
                    color: '#006633',
                    mb: 2
                  }}
                >
                  {calc.title}
                </Typography>

                <Typography
                  sx={{
                    fontFamily: 'Tajawal',
                    color: '#666',
                    lineHeight: 1.8
                  }}
                >
                  {calc.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default CalculatorsPage; 