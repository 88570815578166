import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Alert,
  CircularProgress,
  Grid
} from '@mui/material';
import { Calculate } from '@mui/icons-material';

interface CalculationResult {
  compatibility: 'ضعيف' | 'متوسط' | 'جيد' | 'ممتاز';
  percentage: number;
  description: string;
  recommendations: string[];
}

const SpiritualCompatibilityCalculator: React.FC = () => {
  const [formData, setFormData] = useState({
    person1Name: '',
    person2Name: '',
    relationshipType: 'romantic',
    currentStatus: 'stable',
    valueAlignment: 'high',
    mainChallenges: 'communication',
    relationshipGoal: 'love'
  });

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<CalculationResult | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const calculateResult = (): CalculationResult => {
    let score = 0;
    
    // Calculate score based on relationship type
    if (formData.relationshipType === 'marriage') score += 25;
    else if (formData.relationshipType === 'engagement') score += 20;
    else if (formData.relationshipType === 'romantic') score += 15;

    // Add points for current status
    if (formData.currentStatus === 'stable') score += 25;
    else if (formData.currentStatus === 'challenging') score += 15;
    else if (formData.currentStatus === 'unstable') score += 5;

    // Add points for value alignment
    if (formData.valueAlignment === 'high') score += 25;
    else if (formData.valueAlignment === 'medium') score += 15;
    else if (formData.valueAlignment === 'low') score += 5;

    // Adjust score based on challenges
    if (formData.mainChallenges === 'communication') score -= 10;
    else if (formData.mainChallenges === 'external') score -= 15;
    else if (formData.mainChallenges === 'values') score -= 20;

    // Add points for clear goals
    if (formData.relationshipGoal === 'marriage') score += 25;
    else if (formData.relationshipGoal === 'love') score += 20;
    else if (formData.relationshipGoal === 'unsure') score += 5;

    // Ensure score stays within 0-100 range
    score = Math.max(0, Math.min(100, score));

    // Determine compatibility level
    let compatibility: CalculationResult['compatibility'];
    let description: string;
    let recommendations: string[];

    if (score <= 40) {
      compatibility = 'ضعيف';
      description = 'التوافق الروحي منخفض ويحتاج إلى اهتمام خاص';
      recommendations = [
        'استشارة روحانية متخصصة',
        'العمل على تقوية الروابط الروحية',
        'تحسين التواصل والتفاهم المشترك',
        'البحث عن نقاط التوافق وتعزيزها'
      ];
    } else if (score <= 60) {
      compatibility = 'متوسط';
      description = 'هناك توافق روحي معقول مع إمكانية للتحسين';
      recommendations = [
        'تعزيز الجوانب الإيجابية في العلاقة',
        'العمل على فهم احتياجات الطرف الآخر',
        'المشاركة في أنشطة روحانية مشتركة',
        'تقوية الروابط العاطفية والروحية'
      ];
    } else if (score <= 80) {
      compatibility = 'جيد';
      description = 'التوافق الروحي جيد مع وجود أساس قوي للعلاقة';
      recommendations = [
        'الحفاظ على مستوى التواصل الجيد',
        'تطوير الأهداف المشتركة',
        'تعزيز الثقة المتبادلة',
        'الاستمرار في النمو الروحي المشترك'
      ];
    } else {
      compatibility = 'ممتاز';
      description = 'توافق روحي ممتاز يبشر بعلاقة ناجحة ومستقرة';
      recommendations = [
        'الحفاظ على هذا المستوى من التوافق',
        'مشاركة تجربتكم مع الآخرين',
        'وضع خطط مستقبلية مشتركة',
        'تعزيز الروابط الروحية القوية'
      ];
    }

    return { compatibility, percentage: score, description, recommendations };
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // Simulate calculation delay
    setTimeout(() => {
      const calculationResult = calculateResult();
      setResult(calculationResult);
      setLoading(false);
    }, 1500);
  };

  return (
    <Box sx={{ py: { xs: 4, md: 6 }, backgroundColor: '#f8f8f8' }}>
      <Container maxWidth="md">
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '1.8rem', md: '2.2rem' },
            fontWeight: 700,
            textAlign: 'center',
            color: '#006633',
            mb: 2,
            fontFamily: 'Tajawal'
          }}
        >
          حاسبة التوافق الروحي
        </Typography>

        <Typography
          sx={{
            textAlign: 'center',
            color: '#666',
            mb: 4,
            fontFamily: 'Tajawal',
            fontSize: { xs: '1rem', md: '1.1rem' }
          }}
        >
          أدخل المعلومات المطلوبة لتحليل مدى التوافق الروحي بين الطرفين
        </Typography>

        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, md: 4 },
            backgroundColor: 'white',
            borderRadius: 2
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="اسم الشخص الأول"
                    name="person1Name"
                    value={formData.person1Name}
                    onChange={handleChange}
                    required
                    InputLabelProps={{
                      sx: { fontFamily: 'Tajawal' }
                    }}
                    InputProps={{
                      sx: { fontFamily: 'Tajawal' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="اسم الشخص الثاني"
                    name="person2Name"
                    value={formData.person2Name}
                    onChange={handleChange}
                    required
                    InputLabelProps={{
                      sx: { fontFamily: 'Tajawal' }
                    }}
                    InputProps={{
                      sx: { fontFamily: 'Tajawal' }
                    }}
                  />
                </Grid>
              </Grid>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  ما نوع العلاقة التي تربطكما؟
                </FormLabel>
                <RadioGroup
                  name="relationshipType"
                  value={formData.relationshipType}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="romantic" 
                    control={<Radio />} 
                    label="علاقة عاطفية"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="engagement" 
                    control={<Radio />} 
                    label="خطوبة"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="marriage" 
                    control={<Radio />} 
                    label="زواج"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  كيف تصف علاقتكما الحالية؟
                </FormLabel>
                <RadioGroup
                  name="currentStatus"
                  value={formData.currentStatus}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="stable" 
                    control={<Radio />} 
                    label="مستقرة ومتناغمة"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="challenging" 
                    control={<Radio />} 
                    label="فيها بعض التحديات"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="unstable" 
                    control={<Radio />} 
                    label="غير مستقرة"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  ما مدى التوافق بينكما في القيم والأهداف؟
                </FormLabel>
                <RadioGroup
                  name="valueAlignment"
                  value={formData.valueAlignment}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="high" 
                    control={<Radio />} 
                    label="متوافقان بشكل كبير"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="medium" 
                    control={<Radio />} 
                    label="بعض الاختلافات"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="low" 
                    control={<Radio />} 
                    label="غير متوافقين"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  ما هي أهم التحديات التي تواجه العلاقة؟
                </FormLabel>
                <RadioGroup
                  name="mainChallenges"
                  value={formData.mainChallenges}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="communication" 
                    control={<Radio />} 
                    label="قلة التواصل أو الفهم المتبادل"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="external" 
                    control={<Radio />} 
                    label="تدخلات خارجية أو اعتراضات"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="values" 
                    control={<Radio />} 
                    label="اختلاف القيم أو الأهداف"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  ما هو هدفك الأساسي من هذه العلاقة؟
                </FormLabel>
                <RadioGroup
                  name="relationshipGoal"
                  value={formData.relationshipGoal}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="love" 
                    control={<Radio />} 
                    label="الحب والاستقرار"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="marriage" 
                    control={<Radio />} 
                    label="الزواج وبناء عائلة"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="unsure" 
                    control={<Radio />} 
                    label="غير متأكد"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Calculate />}
                sx={{
                  py: 1.5,
                  backgroundColor: 'var(--primary-green)',
                  fontFamily: 'Tajawal',
                  fontSize: '1.1rem',
                  '& .MuiButton-startIcon': {
                    marginRight: 0,
                    marginLeft: '12px'
                  },
                  '&:hover': {
                    backgroundColor: 'var(--dark-green)'
                  }
                }}
              >
                {loading ? 'جاري التحليل...' : 'تحليل التوافق'}
              </Button>
            </Box>
          </form>

          {result && (
            <Box sx={{ mt: 4 }}>
              <Divider sx={{ mb: 3 }} />
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Tajawal',
                  fontWeight: 600,
                  color: '#006633',
                  mb: 2
                }}
              >
                نتيجة التحليل
              </Typography>

              <Alert 
                severity={
                  result.compatibility === 'ممتاز' ? 'success' :
                  result.compatibility === 'جيد' ? 'info' :
                  result.compatibility === 'متوسط' ? 'warning' : 'error'
                }
                sx={{ mb: 2, fontFamily: 'Tajawal' }}
              >
                <Typography sx={{ fontWeight: 600, mb: 1, fontFamily: 'Tajawal' }}>
                  نسبة التوافق: {result.percentage}%
                </Typography>
                <Typography sx={{ fontWeight: 600, mb: 1, fontFamily: 'Tajawal' }}>
                  مستوى التوافق: {result.compatibility}
                </Typography>
                {result.description}
              </Alert>

              <Typography
                sx={{
                  fontFamily: 'Tajawal',
                  fontWeight: 600,
                  color: '#006633',
                  mb: 1
                }}
              >
                التوصيات:
              </Typography>
              <ul style={{ paddingRight: '20px' }}>
                {result.recommendations.map((rec, index) => (
                  <li key={index} style={{ fontFamily: 'Tajawal', marginBottom: '8px' }}>
                    {rec}
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default SpiritualCompatibilityCalculator; 