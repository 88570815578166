import '../utils/jquery';
import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Button, Box, Paper } from '@mui/material';
import ArticleCard from '../components/ArticleCard';
import ServiceCard from '../components/ServiceCard';
import HeroCarousel from '../components/HeroCarousel';
import SectionTitle from '../components/SectionTitle';
import { useNavigate } from 'react-router-dom';
import { Send, Psychology, Headphones, Chat, Calculate, Flag, WhatsApp } from '@mui/icons-material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { supabase } from '../utils/supabase';
import TestimonialsSection from '../components/TestimonialsSection';

const services = [
  {
    title: 'ابدأ الكشف الآن',
    description: 'اكتشف أسرار حياتك وحل مشاكلك الروحانية مع كشف الشيخ المخصص.',
    icon: <Psychology sx={{ fontSize: 40 }} />,
    link: '/حاسبات',
    buttonText: 'ابدأ الكشف'
  },
  {
    title: 'استمع للرقية الآن',
    description: 'رقية شرعية بصوت الشيخ كنجور لتنقية الطاقة وجلب الطمأنينة.',
    icon: <Headphones sx={{ fontSize: 40 }} />,
    link: '/الرقية-الشرعية/رقية-الشيخ-كنجور',
    buttonText: 'استمع الآن'
  },
  {
    title: 'طلب استشارة خاصة',
    description: 'احصل على استشارة مخصصة مباشرة من الشيخ لحل مشاكلك الروحانية.',
    icon: <Chat sx={{ fontSize: 40 }} />,
    link: '/اتصل-بنا',
    buttonText: 'احجز استشارتك'
  },
  {
    title: 'تواصل مباشرة مع الشيخ',
    description: 'احصل على اتصال مباشر مع الشيخ لمساعدتك في حل مشاكلك وتوجيهك نحو الحلول بدقة.',
    icon: <WhatsApp sx={{ fontSize: 40 }} />,
    link: 'https://wa.me/212604010304',
    buttonText: 'تواصل الآن'
  }
];

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [isClient, setIsClient] = React.useState(false);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const fetchArticles = async () => {
      const { data, error } = await supabase
        .from('articles')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching articles:', error);
        return;
      }

      setArticles(data || []);
      setLoading(false);
    };

    fetchArticles();
  }, []);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  // Log the articles data to verify it's loaded correctly
  console.log('Articles from JSON:', articles);

  const handleArticleClick = (article: any) => {
    if (!isDragging) {
      const urlTitle = article.title
        .replace(/ /g, '-')
        .replace(/[^\u0621-\u064A0-9-]/g, '');
      navigate(`/${urlTitle}`);
    }
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      {/* Hero Carousel */}
      <Box sx={{ 
        backgroundColor: 'white',
        width: '100%',
        overflow: 'hidden'
      }}>
        <Box sx={{ 
          width: '100%',
          margin: 0,
          padding: 0,
          mb: { xs: 4, md: 8 },
          '& > *': {
            width: '100%',
            margin: 0,
            padding: 0
          }
        }}>
          <HeroCarousel />
        </Box>

        {/* Main Content */}
        <Container 
          maxWidth="lg" 
          disableGutters
          sx={{ 
            width: '100%',
            px: { xs: 0, sm: 2, md: 3 }
          }}
        >
          <Box sx={{ mb: { xs: 6, md: 10 }, px: { xs: 2, md: 0 } }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '2rem', md: '2.5rem' },
                fontWeight: 700,
                textAlign: 'center',
                color: '#006633',
                mb: 2,
                fontFamily: 'Tajawal',
              }}
            >
              شيخي
            </Typography>
            
            {/* Decorative Line */}
            <Box
              sx={{
                width: { xs: '80px', md: '120px' },
                height: '4px',
                backgroundColor: '#eee',
                margin: '0 auto',
                position: 'relative',
                mb: 3,
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  width: { xs: '30px', md: '40px' },
                  height: '4px',
                  backgroundColor: '#006633',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }
              }}
            />

            <Typography
              variant="body1"
              className="arabic-content"
              sx={{
                fontSize: { xs: '1rem', md: '1.2rem' },
                lineHeight: 2,
                maxWidth: '1000px',
                mx: 'auto',
                mb: 4,
                color: '#333',
                textAlign: 'justify',
                px: { xs: 2, md: 0 }
              }}
            >
              مرحباً بك في بواب شيخي، المنصة الرائدة في العلوم الروحانية والفلكية والعلاج بالأعشاب المغربية الأصيلة. يقدم الشيخ كنجور خبرته العميقة في مجالات متعددة، من العلاج الروحاني والكشف عن الطاقات السلبية إلى العلاج بالأعشاب المغربية التقليدية. نحن نؤمن بن لكل شخص رحلة روحانية فريدة، ونسعى لتقديم حلول مخصصة تناسب احتياجاتكم الخاصة.
            </Typography>

            {/* CTA Button */}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                size="large"
                startIcon={<Send sx={{ ml: 1 }} />}
                sx={{
                  backgroundColor: 'var(--primary-green)',
                  color: 'white',
                  px: { xs: 4, md: 5 },
                  py: { xs: 1.5, md: 1 },
                  height: { xs: '48px', md: '48px' },
                  fontSize: { xs: '1rem', md: '1.1rem' },
                  fontFamily: 'Noto Naskh Arabic',
                  fontWeight: 600,
                  borderRadius: 2,
                  width: { xs: '100%', sm: 'auto' },
                  maxWidth: { xs: '320px', sm: 'none' },
                  boxShadow: '0 4px 14px rgba(26, 77, 46, 0.2)',
                  '&:hover': {
                    backgroundColor: 'var(--dark-green)',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 20px rgba(26, 77, 46, 0.3)',
                  },
                  transition: 'all 0.3s ease',
                }}
              >
                ارسال طلب تكلفة
              </Button>
            </Box>
          </Box>

          {/* Services Section */}
          <Box sx={{ 
            mb: { xs: 6, md: 10 }, 
            px: { xs: 2, md: 0 },
            mt: { xs: 4, md: 6 }
          }}>
            <Grid 
              container 
              spacing={{ xs: 3, md: 4 }} 
              sx={{ 
                mb: { xs: 4, md: 8 },
                mx: 0,
                width: '100%'
              }}
            >
              {services.map((service, index) => (
                <Grid 
                  item 
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                >
                  <ServiceCard 
                    service={service}
                    sx={{
                      height: { xs: 'auto', md: '100%' },
                      p: { xs: 3, md: 3 },
                      maxWidth: { xs: '100%', sm: 'none' },
                      mx: { xs: 'auto', sm: 0 },
                      '& .MuiCardContent-root': {
                        p: { xs: 4, md: 3 }
                      },
                      '& .icon-container': {
                        width: { xs: '90px', md: '80px' },
                        height: { xs: '90px', md: '80px' }
                      },
                      '& .service-title': {
                        fontSize: { xs: '1.6rem', md: '1.5rem' },
                        mb: { xs: 2.5, md: 2 }
                      },
                      '& .service-description': {
                        fontSize: { xs: '1.1rem', md: '1rem' },
                        lineHeight: { xs: 1.9, md: 1.8 }
                      }
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Articles Section */}
          <Box 
            sx={{ 
              backgroundColor: '#f8f8f8',
              width: '100vw',
              position: 'relative',
              left: '50%',
              right: '50%',
              marginLeft: '-50vw',
              marginRight: '-50vw',
              py: { xs: 6, md: 10 },
              mb: { xs: 6, md: 10 }
            }}
          >
            <Container maxWidth="lg">
              <Box sx={{ textAlign: 'center', mb: 6 }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '2rem', md: '2.5rem' },
                    fontWeight: 700,
                    color: '#006633',
                    mb: 1,
                    fontFamily: 'Tajawal',
                    position: 'relative',
                    display: 'inline-block',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -8,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: '40%',
                      height: 3,
                      background: 'linear-gradient(90deg, transparent, #006633, transparent)',
                      borderRadius: '2px'
                    }
                  }}
                >
                  أحدث المقالات
                </Typography>
                <Typography
                  sx={{
                    color: '#666',
                    mt: 3,
                    fontFamily: 'Tajawal',
                    fontSize: '1.1rem'
                  }}
                >
                  احدث المقالات الجديدة والمميزة التي أضيفت في المدونة
                </Typography>
              </Box>

              <Box sx={{ 
                '& .react-multi-carousel-dot-list': {
                  bottom: '-40px'
                },
                '& .react-multi-carousel-dot button': {
                  borderColor: '#006633',
                  opacity: 0.5,
                  transition: 'all 0.3s ease',
                  width: 8,
                  height: 8,
                  borderWidth: 1,
                  margin: '0 4px'
                },
                '& .react-multi-carousel-dot--active button': {
                  backgroundColor: '#006633',
                  opacity: 1,
                  transform: 'scale(1.2)'
                }
              }}>
                <Carousel
                  responsive={responsive}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={5000}
                  showDots={true}
                  arrows={false}
                  rtl={true}
                  renderDotsOutside={true}
                  containerClass="carousel-container"
                  itemClass="carousel-item-padding"
                  draggable={true}
                  swipeable={true}
                  beforeChange={() => setIsDragging(true)}
                  afterChange={() => setIsDragging(false)}
                >
                  {articles.map((article: any) => (
                    <Box
                      key={article.id}
                      onClick={() => handleArticleClick(article)}
                      sx={{
                        mx: 2,
                        cursor: isDragging ? 'grabbing' : 'pointer',
                        backgroundColor: 'white',
                        borderRadius: 2,
                        overflow: 'hidden',
                        transition: 'all 0.3s ease',
                        height: '100%',
                        boxShadow: '0 0 0 1px rgba(0,0,0,0.05)',
                        '&:hover': {
                          transform: isDragging ? 'none' : 'translateY(-8px)',
                          boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                        }
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          paddingTop: '60%',
                          overflow: 'hidden',
                          pointerEvents: 'none'
                        }}
                      >
                        <img
                          src={article.image}
                          alt={article.title}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            userSelect: 'none'
                          }}
                        />
                      </Box>
                      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontFamily: 'Tajawal',
                            fontWeight: 700,
                            fontSize: '1.2rem',
                            mb: 2,
                            lineHeight: 1.5,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical'
                          }}
                        >
                          {article.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Tajawal',
                            color: '#666',
                            fontSize: '0.95rem',
                            lineHeight: 1.8,
                            mb: 2,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            flex: 1
                          }}
                        >
                          {article.content.replace(/<[^>]*>/g, '').slice(0, 150)}...
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mt: 'auto',
                            pt: 2,
                            borderTop: '1px solid rgba(0,102,51,0.1)'
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Tajawal',
                              color: '#006633',
                              fontSize: '0.9rem',
                              fontWeight: 600,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              '&:hover': {
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            اقرأ المزيد
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Carousel>
              </Box>
            </Container>
          </Box>

          <TestimonialsSection />
        </Container>
      </Box>
    </Box>
  );
};

export default HomePage;
